import React, { useState } from "react";
import axios from "axios";
import Title from "./Title";
import { Form, Button } from "react-bootstrap";

const ContactForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://formtest.cflmstest.com/api/contact/email.php",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <div className="mt-50 mb-50">
      <div className="align-content-center font-weight-bold form">
        <Title title="Have a different question?" />
        <Form onSubmit={handleOnSubmit}>
          <div>
            <Form.Group controlId="formGridName">
              <Form.Label>Your Name (required)</Form.Label>
              <Form.Control type="text" name="name" placeholder="Jon Doe" />
            </Form.Group>
          </div>
          <div className="mt-20">
            <Form.Group controlId="formGridEmail">
              <Form.Label>Your Email (required)</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="example@domain.com"
              />
            </Form.Group>
          </div>

          <div>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Your Question (required)</Form.Label>
              <Form.Control
                type="textarea"
                name="message"
                className="inputarea"
              />
            </Form.Group>
          </div>

          <Button
            className="btn btn-secondary"
            variant="primary"
            type="submit"
            disabled={serverState.submitting}
          >
            Send
          </Button>
          {serverState.status && (
            <p className={!serverState.status.ok ? "errorMsg" : ""}>
              {serverState.status.msg}
            </p>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
