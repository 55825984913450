import React, { useState } from "react";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className="card-header" type="button" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

const Accordion2 = () => {
  const [activeKey, setActiveKey] = useState(1);

  const data = [
    {
      name: "",
      info: "",
    },
    {
      name: "Does the inspector have a construction related background?",
      info: "Anyone can sign up to take a course on 'How to Do Home Inspections' no matter what type of work related background or experience they may have, so it is imperative to choose an inspector that can offer you years of experience prior to hiring them to inspect your home versus one who has just completed a 'How To' course. Young Home Inspections' inspectors all have some type of construction background; electrical, framing, plumbing, etc., and the majority of our inspectors have been with our team for 10+ years.",
    },
    {
      name: "Does the inspector have general liability insurance?",
      info: " Young Home Inspections does maintain the required general liability insurance.",
    },
    {
      name: "Does the inspector carry worker's compensation insurance?",
      info: " A visual examination of the house from top to bottom. The inspector will examine the heating and air conditioning system, the interior, plumbing and electrical systems, the roof and visible insulation, walls, ceilings, floors, windows and doors, the foundation and visible structure. ",
    },
    {
      name: "Is the inspector a member of the International Association of Certified Home Inspectors (InterNACHI)?",
      info: " The International Association of Certified Home Inspectors (InterNACHI) is the leading International professional organization and requires the following from each individual inspector: Passing scores on rigorous inspector examination, Successful completion of InterNACHI Code of Ethics Course, Successful completion of thorough InterNACHI pre-certification training program, Each member must complete and maintain 24 hours of continuing education per year to maintain membership., Conducting business within the guidelines of the InterNACHI Code of Ethics as well as the InterNACHI Standards of Practice",
    },
    {
      name: "Is the home inspector claiming that his or her reports 'meet or exceed InterNACHI Standards?'",
      info: " If so, it is likely that they are not Certified Members of InterNACHI: they are simply claiming to be 'as good as' InterNACHI members. At Young Home Inspections each of our inspectors are InterNACHI Certified as well as state licensed by the state of Florida.",
    },
    {
      name: "How long has the company been in business?",
      info: " The length of time a company has been in business is a good indication that it has been successful in providing a fine and reputable service. Young Home Inspections is locally owned, not a franchise, and has been protecting Tampa Bay Homebuyers since 1989.",
    },
    {
      name: "Will I get the inspector's voice mail during the business day, or a professional office staff to assist me?",
      info: " Since sole operators do not always have the resources to have a professional office staff handle their incoming calls, you may find some inspectors will answer the phone themselves, sometimes during the course of performing a home inspection on someone's property. Or, you may get passed into a voice mail and have to await a reply. Young Home Inspections has a courteous and professional office staff available Monday through Friday from 7:30am to 6:00pm to assist you, and our regular office staff also rotates weekend phone coverage.",
    },
    {
      name: "Should I attend the home inspection?",
      info: " Young Home Inspections highly encourages your presence at the inspection so that the inspector can review the findings with you at the end of the inspection. If you are unable to be present for the entire inspection, we recommend that you arrive between the middle and the end of the inspection to review the report.",
    },
    {
      name: "When will I receive the report?",
      info: " Your home inspection report will be posted online and available to you the very next day, unless inspection wsa on Saturday and then you would receive on the following monday. With an email directly from our Quality Assurance division, we will notify you when your report is finished. ",
    },
    {
      name: "How long will it take to evaluate my property?",
      info: " Buyer beware if an inspector promotes performing your inspection in 1 to 1-1/2 hours. The home inspection process is not an area that you want to rush through! The length of the inspection should not be important if the end result is thoroughness! Young Home Inspections' typical inspection will take 2-1/2 to 3 hours; however, our office staff can provide you with an estimated timeframe based on the particulars of your property to be inspected.",
    },
  ];
  return (
    <div className="App mb-20">
      <Accordion defaultActiveKey="1" activeKey={activeKey}>
        {data.map((item, index) => (
          <Card key={index}>
            <CustomToggle
              as={Card.Header}
              eventKey={index}
              handleClick={() => {
                if (activeKey === index) {
                  setActiveKey(null);
                } else {
                  setActiveKey(index);
                }
              }}
            >
              {activeKey === index ? "-" : "+"}&nbsp;&nbsp;&nbsp;&nbsp;
              {item.name}
            </CustomToggle>
            <Accordion.Collapse eventKey={index}>
              <Card.Body>{item.info}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};
export default Accordion2;
