import React from "react";
import { Container } from "react-bootstrap";
import Accordion1 from "../components/Accordion1";
import Accordion2 from "../components/Accordion2";
import Accordion3 from "../components/Accordion3";
import Faqshero from "../components/hero/Faqshero";
import Layout from "../components/Layout";
import Services from "../components/Services";
import ContactForm from "../components/Shortform";

const faqs = () => {
  return (
    <Layout>
      <Faqshero />
      <Container>
        <h3>General Questions</h3>
        <div className="faqform ">
          <ContactForm />
        </div>
        <div className="accordionarea">
          <Accordion1 />
        </div>
        <div className="mt-100">
          <h4>Choosing Your Home Inspector</h4>
          <Accordion2 />
        </div>
        <div>
          <h4>What to Expect After the Inspection</h4>
          <Accordion3 />
        </div>
      </Container>
      <Services />
    </Layout>
  );
};

export default faqs;
