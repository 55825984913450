import React from "react";
import { Container } from "react-bootstrap";

const Faqshero = () => {
  return (
    <div className="overlay-hero banner banner-page banner-faqs mb-30">
      <Container>
        <div className="overlay-herotxt">
          <h2 className="banner-head">Frequently Asked Questions</h2>
        </div>
        <div className="overlay-herosub">
          <p>Let Us Answer Yours</p>
        </div>
      </Container>
    </div>
  );
};

export default Faqshero;
