import React, { useState } from "react";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className="card-header" type="button" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

const Accordion1 = () => {
  const [activeKey, setActiveKey] = useState(1);

  const data = [
    {
      name: "",
      info: "",
    },
    {
      name: "What is a home inspection?",
      info: "A home inspection is a visual examination of the physical structure and major systems of a home.",
    },
    {
      name: "Why do I need a home inspection?",
      info: " The purchase of a home is probably the largest single investment you will ever make. You should know exactly what to expect, both indoors and out- in terms of repair and maintenance and their costs. A fresh coat of paint could be hiding serious structural problems. Water marks in areas throughout the home could indicate a chronic seepage problem or may simply be the result of a single incident. The home inspector interprets these and other clues, and he presents his professional opinion as to the condition of the property before you buy, so you can avoid unpleasant surprises afterwards. Of course, a home inspection will also point out the positive aspects of a home, as well as the type of maintenance that will be necessary to keep it in good shape. After the inspection you will have a much clearer understanding of what it is you are about to purchase, and you will be able to make your decision confidently. If you have owned your home for a period of time, a home inspection can identify problems in the making and recommend preventive measures, which might avoid future repairs.",
    },
    {
      name: "What does a home inspection include?",
      info: " A visual examination of the house from top to bottom. The inspector will examine the heating and air conditioning system, the interior, plumbing and electrical systems, the roof and visible insulation, walls, ceilings, floors, windows and doors, the foundation and visible structure. ",
    },
    {
      name: "Can I do it myself?",
      info: " Even the most experienced homeowner lacks the knowledge and expertise of a professional home inspector who has inspected hundreds, perhaps thousands of homes in his career. An inspector is equally familiar with all the elements of home construction and with the proper installation, maintenance and interrelationship of those elements. Above all, most buyers find it very difficult to remain completely objective and unemotional about the house they really want, and this may lead to a poor assessment. Even if you have the knowledge, will the home seller allow you to spend hours opening closets and going into the attic/crawl spaces? ",
    },
    {
      name: "What will a home inspection cost?",
      info: " The inspection fee will vary depending upon the size of the house, particular features of the house, age, special structures, etc. However, the cost should not be a factor in the decision whether or not to have a home inspection. You might save many times the cost of the inspection if you are able to negotiate the repairs of significant problems revealed by the inspector. ",
    },
    {
      name: "Can a house 'fail' inspection?",
      info: " No. A professional home inspection is simply an examination into the current condition of your prospective home and is not an appraisal or a Municipal Code inspection. A home inspector will not pass or fail a house, but will simply describe its condition and indicate which items will be in need of immediate or near future repair or replacement. ",
    },
  ];
  return (
    <div className="App mb-30">
      <Accordion defaultActiveKey="1" activeKey={activeKey}>
        {data.map((item, index) => (
          <Card key={index}>
            <CustomToggle
              as={Card.Header}
              eventKey={index}
              handleClick={() => {
                if (activeKey === index) {
                  setActiveKey(null);
                } else {
                  setActiveKey(index);
                }
              }}
            >
              {activeKey === index ? "-" : "+"}&nbsp;&nbsp;&nbsp;&nbsp;
              {item.name}
            </CustomToggle>
            <Accordion.Collapse eventKey={index}>
              <Card.Body>{item.info}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};
export default Accordion1;
