import React, { useState } from "react";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className="card-header" type="button" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

const Accordion3 = () => {
  const [activeKey, setActiveKey] = useState(1);

  const data = [
    {
      name: "",
      info: "",
    },
    {
      name: "Will the inspector be available to answer questions days after the inspection?",
      info: "Young Home Inspections' office staff is always in full communication with the field. We will relay any messages to our inspectors and they will be more than happy to return calls for any questions our clients may have, any time after the inspection and during. Once the inspector receives a message from Young Home Inspections' office, you should receive a call within 24 hours. Our inspectors will always attempt to return phone calls in between inspections throughout the day or later in the evening after their last inspection.",
    },
    {
      name: "Does the inspector provide a service where he will perform a re-inspection to evaluate deficiencies reported during the original inspection?",
      info: " Young Home Inspections does offer an additional service where we will re-visit the property to re-evaluate earlier reported deficiencies to ensure they have been properly repaired.",
    },
  ];
  return (
    <div className="App mb-20">
      <Accordion defaultActiveKey="1" activeKey={activeKey}>
        {data.map((item, index) => (
          <Card key={index}>
            <CustomToggle
              as={Card.Header}
              eventKey={index}
              handleClick={() => {
                if (activeKey === index) {
                  setActiveKey(null);
                } else {
                  setActiveKey(index);
                }
              }}
            >
              {activeKey === index ? "-" : "+"}&nbsp;&nbsp;&nbsp;&nbsp;
              {item.name}
            </CustomToggle>
            <Accordion.Collapse eventKey={index}>
              <Card.Body>{item.info}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};
export default Accordion3;
